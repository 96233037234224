// In src/pages/Home.js
import React from 'react';
import '../App.css'; // For specific styling of the Home page

function Home() {
    return (
        <div className="home">
            <h1>Welcome to McGuires Bar!</h1>
        </div>
    );
}

export default Home;
