// In src/pages/Home.js
import React from 'react';
import '../App.css'; // For specific styling of the Home page

function AboutUs() {
    return (
        <div className="home">
            <h1>McGuires Pub, Brickens</h1>
            <p>McGuires Pub is located in the scenic village of Brickens, on the N60 between Claremorris and Ballyhaunis in County Mayo.</p>
            <p>Recently opened under new management, here you will find a good and lively atmosphere where all are welcome in the door. Come in any day and have a drink at the bar, play a game of darts or pool with your friends, or put on some music while you relax at the tables.</p>
            <p>Guaranteed a nice, welcoming atmosphere from both staff and patrons!</p>
        </div>
    );
}

export default AboutUs;
