// In src/pages/Home.js
import React from 'react';
import '../App.css'; // For specific styling of the Home page

function Contact() {
    return (
        <div className="home">
            <h1>McGuires Brickens</h1>
            <p>Located on the N60, halfway between Claremorris and Ballyhaunis.</p>
            <p>Brickens,<br />Claremorris,<br />Co. Mayo,<br />F12 F443</p>
        </div>
    );
}

export default Contact;
